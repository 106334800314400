<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new outbound email transport</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new outbound email transport</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <p>
                                An outbound email transport delivers email dispatches to contacts.
                                After you create and configure a new outbound email transport, you can
                                select it from any domain's settings to send emails from that domain.
                            </p>
                            <!-- <p>
                                Many web hosting companies offer SMTP service, so if you have an account
                                like that you can usually select the <strong>SMTP</strong> transport type
                                and configure its settings.
                            </p> -->
                            <!-- <p>
                                Security: It's better to use a provider with an API that can issue you an
                                API token. This way, if our servers are ever compromised you can revoke the
                                API token and the attackers only get limited time access to send emails on
                                your behalf, instead of complete access to your account at the provider.
                            </p> -->
                            <v-text-field
                                ref="outboundEmailTransportLabelInput"
                                v-model="newOutboundEmailTransportLabel"
                                label="Label"
                                :rules="newOutboundEmailTransportLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-select outlined dense :items="newOutboundEmailTransportTypeChoices" v-model="newOutboundEmailTransportType" color="teal darken-2" label="Provider Type" required hint="Choose how to connect to this outbound email transport">
                                <!-- <template #prepend>
                                    @
                                </template> -->
                                <!-- <template #append-outer>
                                    <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                        <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template> -->
                            </v-select>

                            <p class="mb-6">
                                <!-- Don't see the domain you want to use?
                                <router-link :to="{ name: 'account-create-domain', params: { accountId: this.$route.params.accountId } }">Add a domain...</router-link> -->
                                If your email provider doesn't offer standard SMTP service, and you don't see
                                it on the list here, please <a href="https://liberty.io/contact/" target="_blank">let us know</a>.
                            </p>

                            <component :is="configComponent" v-model="newOutboundEmailTransportConfig" :isEditMode="true"></component>

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>

                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Outbound Email Transport Config <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.newOutboundEmailTransportConfig, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import None from '@/components/transport-config/None.vue';
import Local from '@/components/transport-config/Local.vue';
import Smtp from '@/components/transport-config/Smtp.vue';

export default {
    components: {
        None,
        Local,
        Smtp,
    },
    data: () => ({
        error: null,
        // create form
        createOutboundEmailTransportForm: null,
        newOutboundEmailTransportType: null,
        newOutboundEmailTransportTypeChoices: [], // will be loaded from server
        newOutboundEmailTransportLabel: null,
        newOutboundEmailTransportLabelRules: [
            (v) => !!v || 'Label is required',
        ],
        submitActionTimestamp: null,
        newOutboundEmailTransportConfig: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newOutboundEmailTransportType === 'string' && this.newOutboundEmailTransportType.trim().length > 0 && typeof this.newOutboundEmailTransportLabel === 'string' && this.newOutboundEmailTransportLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        configComponent() {
            switch (this.newOutboundEmailTransportType) {
            case 'local':
                return 'Local';
            case 'smtp':
                return 'Smtp';
            default:
                return 'None';
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadOutboundEmailTransportTypeList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOutboundEmailTransportTypeList: true });
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransportType.search(); // optional: { q: 'search term' }
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.newOutboundEmailTransportTypeChoices = response.list; // each item has { text, value, hint }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load outbound email transport type list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOutboundEmailTransportTypeList: false });
            }
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newOutboundEmailTransportLabel === null || this.newOutboundEmailTransportLabel.length === 0) {
        //                 this.newOutboundEmailTransportLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        create() {
            if (Number.isInteger(this.submitActionTimestamp) && this.submitActionTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitActionTimestamp = Date.now();
            if (!this.isFormComplete) {
                return;
            }
            this.createOutboundEmailTransport();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createOutboundEmailTransport() {
            try {
                this.error = false;
                this.$store.commit('loading', { createOutboundEmailTransport: true });
                console.log('createOutboundEmailTransport');
                const request = {
                    label: this.newOutboundEmailTransportLabel,
                    type: this.newOutboundEmailTransportType,
                    config: this.newOutboundEmailTransportConfig ?? {},
                };
                // if (this.website) {
                //     request.website_id = this.website.id;
                // }
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransport.create(request);
                console.log('createOutboundEmailTransport response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-outbound-email-transport', params: { accountId: this.$route.params.accountId, outboundEmailTransportId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create outbound email transport' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create outbound email transport' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create outbound email transport', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create outbound email transport' });
            } finally {
                this.$store.commit('loading', { createOutboundEmailTransport: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadOutboundEmailTransportTypeList();
        // this.loadDomainList();
        // if (this.$route.query.website_id) {
        //     this.loadWebsite(this.$route.query.website_id);
        // }
    },
};
</script>
