<template>
    <v-form>
        <!-- <p>No configuration.</p> -->
    </v-form>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    },
};
</script>
