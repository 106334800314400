<template>
    <v-form @submit.prevent="onChange">
        <template v-if="!isEditMode">
            <p class="mb-0">
            Host: {{ host }}
            </p>
            <p class="mb-0">
            Port: {{ port }}
            </p>
            <p class="mb-0">
            StartTLS: {{ starttls }}
            </p>
            <p class="mb-0">
            Username: {{ username }}
            </p>
            <p class="mb-0">
            Password: ********
            </p>
        </template>
        <template v-if="isEditMode">
            <v-text-field v-model="host" label="Host" hint="Host name or IP address" @change="onChange" dense/>
            <v-checkbox v-model="starttls" label="StartTLS" hint="Does this provider support StartTLS?" @change="onChange" dense/>
            <v-text-field v-model="port" label="Port" hint="Port number" @change="onChange" dense/>
            <v-text-field v-model="username" label="Username" hint="Username to access the SMTP server" @change="onChange" dense/>
            <v-text-field v-model="password" label="Password" hint="Password to access the SMTP server" :type="isPasswordVisible ? 'text' : 'password'" @change="onChange" dense>
                <template #append>
                    <v-btn icon @click="isPasswordVisible = !isPasswordVisible" :color="showPasswordButtonColor">
                        <font-awesome-icon :icon="['fas', 'eye']"/>
                    </v-btn>
                </template>
            </v-text-field>
        </template>
    </v-form>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
            default: () => {},
        },
        isEditMode: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data: () => ({
        // isEditMode: false,
        isPasswordVisible: false,
        host: null,
        port: null,
        starttls: null,
        username: null,
        password: null,
    }),
    computed: {
        showPasswordButtonColor() {
            return this.isPasswordVisible ? 'teal darken-2' : 'grey darken-2';
        },
    },
    watch: {
        value() {
            this.update();
        },
        isEditMode() {
            this.update();
        },
    },
    methods: {
        update() {
            this.host = this.value.host;
            this.port = this.value.port;
            this.starttls = this.value.starttls;
            this.username = this.value.username;
            this.password = this.value.password;
        },
        onChange() {
            const portNumber = Number.parseInt(this.port, 10);
            const config = {
                host: this.host,
                port: Number.isInteger(portNumber) ? portNumber : null,
                starttls: this.starttls,
                username: this.username,
                password: this.password,
            };
            this.$emit('input', config);
        },
    },
    mounted() {
        this.update();
    },
};
</script>
